import Iconify from '../components/iconify';

export const CONNECTORS = [
  {
    value: 'google-ads',
    name: 'Google Ads',
    description: 'Google Ads API',
    icon: 'logos:google-ads',
    color: '#00AAEC',
    isPixelAvailable: false,
    isApiAvailable: true,
    caption: 'Popular',
  },
  {
    value: 'facebook',
    name: 'Facebook',
    description: 'Meta Conversion API',
    icon: 'logos:facebook',
    color: '#1877F2',
    isPixelAvailable: true,
    isApiAvailable: true,
    caption: 'Popular',
  },
  {
    value: 'tiktok',
    name: 'TikTok',
    description: 'TikTok Events API',
    icon: 'logos:tiktok-icon',
    color: '#ffcf31',
  },
  {
    value: 'ga4',
    name: 'Google Analytics 4',
    description: 'GA4 Measurement Protocol',
    icon: 'logos:google-analytics',
    color: '#e37400',
    isPixelAvailable: true,
    isApiAvailable: true,
    caption: 'Popular',
  },
  {
    value: 'instagram',
    name: 'Instagram',
    description: 'Meta Conversion API',
    icon: 'skill-icons:instagram',
    color: '#E02D69',
    isPixelAvailable: true,
    isApiAvailable: true,
  },
  {
    value: 'linkedin',
    name: 'LinkedIn',
    description: 'LinkedIn Conversions API',
    icon: 'skill-icons:linkedin',
    color: '#007EBB',
    isPixelAvailable: false,
    isApiAvailable: false,
  },
  {
    value: 'snapchat',
    name: 'Snapchat',
    description: 'Snap Conversions API (CAPI)',
    icon: 'fa-brands:snapchat-square',
    color: '#FFFC00',
    isPixelAvailable: false,
    isApiAvailable: true,
  },
  {
    value: 'microsoft-ads',
    name: 'Microsoft Ads',
    description: 'Microsoft Ads',
    icon: 'logos:microsoft-icon',
    color: '#007EBB',
    isPixelAvailable: true,
    isApiAvailable: true,
  },
  {
    value: 'webhook',
    name: 'Webhooks',
    description: 'Send data to any URL',
    icon: 'mdi:webhook',
    color: '#1877F2',
    isPixelAvailable: false,
    isApiAvailable: true,
  },
  /* {
    value: 'twitter',
    name: 'Twitter',
    icon: 'skill-icons:twitter',
    color: '#00AAEC',
    isPixelAvailable: false,
    isApiAvailable: false,
  }, */
  {
    value: 'more',
    name: 'And More',
    description: 'Try it yourself!',
    icon: 'solar:menu-dots-bold',
    color: 'text.secondary',
    isPixelAvailable: false,
    isApiAvailable: true,
    buttonLabel: 'See More',
  },
];

export function getSocial(str) {
  return CONNECTORS.find((x) => x.value === str);
}

export const EVENT_SOURCE_SYSTEMS = [
  {
    value: 'woocommerce',
    name: 'WooCommerce',
    icon: 'logos:woocommerce-icon',
  },
  {
    value: 'shopify',
    name: 'Shopify',
    icon: 'logos:shopify',
  },
  {
    value: 'magento',
    name: 'Magento',
    icon: 'devicon:magento',
  },
  {
    value: 'wordpress',
    name: 'Wordpress',
    icon: 'logos:wordpress-icon',
  },
  {
    value: 'wix',
    name: 'Wix',
    icon: 'logos:wix',
  },
  {
    value: 'prestashop',
    name: 'PrestaShop',
    icon: 'logos:prestashop',
  },
  {
    value: 'hubspot',
    name: 'HubSpot',
    icon: 'logos:hubspot',
  },
  {
    value: 'joomla',
    name: 'Joomla',
    icon: 'logos:joomla',
  },
  {
    value: 'drupal',
    name: 'Drupal',
    icon: 'logos:drupal-icon',
  },
  {
    value: 'custom',
    name: 'Custom',
    icon: 'mdi:code-tags',
  },
  {
    value: 'other',
    name: 'Other',
    icon: 'basil:other-1-outline',
  },
];

export function getEventSourceSystem(str, icon = false, iconWidth = 24) {
  if (icon) {
    const item = EVENT_SOURCE_SYSTEMS.find((x) => x.value === str);
    if (!item) {
      return str;
    }
    return <Iconify icon={item.icon} width={iconWidth} />;
  }
  return EVENT_SOURCE_SYSTEMS.find((x) => x.value === str);
}

export const TRACKING_TAG_ADMIN_STATUS_OPTIONS = [
  {
    value: 10,
    name: 'Special',
    icon: 'solar:chat-round-like-bold',
    color: 'success.main',
  },
  {
    value: 8,
    name: 'Confirmed',
    icon: 'solar:chat-round-money-bold',
    color: 'primary.main',
  },
  {
    value: 5,
    name: 'Unknown',
    icon: 'solar:question-circle-linear',
    color: 'warning.main',
  },
  {
    value: 3,
    name: 'Needs Attention',
    icon: 'solar:shield-warning-bold',
    color: 'error.main',
  },
  {
    value: 1,
    name: 'Disabled',
    icon: 'solar:close-circle-outline',
    color: 'default.main',
  },
];

export function getTrackingTagAdminStatus(str, icon = false, iconWidth = 24) {
  if (icon) {
    const item = TRACKING_TAG_ADMIN_STATUS_OPTIONS.find((x) => x.value === str);
    if (!item) {
      return str;
    }
    return <Iconify icon={item.icon} width={iconWidth} color={item.color} />;
  }
  return TRACKING_TAG_ADMIN_STATUS_OPTIONS.find((x) => x.value === str);
}

export const TIMESTAMP_ALIASES = {
  'browser': {
    title: '',
    icon: 'solar:card-outline',
  },
  'server': {
    title: '',
    icon: 'solar:server-bold',
  },
  'existing-visitor-hash': {
    title: '',
    icon: 'solar:hashtag-circle-linear',
  },
  'existing-source-values': {
    title: '',
    icon: '',
  },
  'existing-event-id': {
    title: '',
    icon: 'solar:bill-list-outline',
  },
  'new': {
    title: '',
    icon: 'solar:play-circle-linear',
  },
  'existing-timestamp': {
    title: '',
    icon: 'solar:sort-by-time-outline',
  },
  'existing-ip-user-agent': {
    title: '',
    icon: 'solar:diploma-verified-outline',
  },
  'existing-pending-event': {
    title: '',
    icon: 'solar:menu-dots-circle-linear',
  },
  'existing-processed-event': {
    title: '',
    icon: 'solar:check-circle-linear',
  },
  'email': {
    title: '',
    icon: 'solar:letter-outline',
  },
  'phoneNumber': {
    title: '',
    icon: 'solar:phone-rounded-linear',
  },
  'externalId': {
    title: '',
    icon: 'solar:bill-outline',
  },
};
